window.modalHandler = () => {
  if (!window.modalInitialized) {
    document.addEventListener('DOMContentLoaded', () => {
      showModal();
      closeModal();
      consentEvent();
      submitEvent();
    });
    window.modalInitialized = true;
  }

  const showModal = () => {
    window.addEventListener('click', (e) => {
      const isBtnToShowModal = e.target.classList.contains('jsShowModal');

      if (isBtnToShowModal) {
        const button = e.target;
        const modalName = button.getAttribute('data-modal');
        const modal = document.querySelector(`#${modalName}`);

        modal.classList.add('active');
        document.body.classList.add('block-scroll');
      }
    });
  };

  const closeModal = () => {
    // old?
    window.addEventListener('click', e => {
      const isCloseBtn = e.target.classList.contains('jsMClose');

      if (isCloseBtn) {
        const close = e.target;
        const modal = close.closest(".jsM");

        modal.classList.remove('active');
        setTimeout(function () {
          modal.classList.remove('m-success');
          const errorNode = modal.querySelector('.m-error-text');
          if (errorNode) {
            errorNode.remove();
          }
        }, 300);
        document.body.classList.remove('block-scroll');
      }
    });

    // after refactor and CSS reduce
    window.addEventListener('click', e => {
      const isCloseButton = e.target.classList.contains('jsModalClose');
      const html = document.querySelector('html');

      if (isCloseButton) {
        const closeButton = e.target;
        const modal = closeButton.closest('.jsModal');

        modal.classList.remove('active');
        document.body.style.overflow = null;
        html.style.overflow = null;
      }
    });
  };

  const consentEvent = () => {
    window.addEventListener('click', e => {
      const isConsent = e.target.offsetParent?.classList.contains('jsMConsent');

      if (isConsent) {
        const consent = e.target.offsetParent;
        const modal = consent.closest('.jsM');
        const checkbox = modal.querySelector('.jsMCheckboxConsent');
        const submit = modal.querySelector('.jsMSubmit');

        checkbox.checked
          ? submit.removeAttribute('disabled')
          : submit.setAttribute('disabled', 'disabled');
      }
    });
  };

  const submitEvent = () => {
    window.addEventListener('click', e => {
      const isSubmitBtn = e.target.classList.contains('jsMSubmit');
      const isInsight = e.target.classList.contains('jsMInsight');
      if (isSubmitBtn) {
        e.preventDefault();
        const submit = e.target;
        const modal = submit.closest('.jsM');
        const title = modal.querySelector('.jsMTitle');
        const successTitle = modal.querySelector('.jsMSuccessTitle');
        const description = modal.querySelector('.jsMDescription');
        const successDescription = modal.querySelector('.jsMSuccessDescription');
        const form = modal.querySelector('.jsMForm');
        const fields = form.querySelectorAll('.jsMField');
        const data = {};
        const url = isInsight ? '/insights/insights-save-popup-form/' : '/solutions/solution-save-popup-form/';
        const errorInputClass = ['modal__input', 'wrong'];
        const activeErrorClass = 'error-msg-insights-active'
        const requiredText = 'This field is required';
        const getMaxText = (length) => `This field is too long (maximum is ${length} characters)`;

        const emailInput = form.querySelector('input[id="email"]');
        const nameInput = form.querySelector('input[id="name"]');
        const companyInput = form.querySelector('input[id="company"]');
        const checkboxInput = form.querySelector('input[type="checkbox"]');

        const emailError = form.querySelector('.jsEmailError');
        const nameError = form.querySelector('.jsNameError');
        const companyError = form.querySelector('.jsCompanyError');


        let isValid = true;

        if (!emailInput.value.trim()) {
          emailInput.classList.add(...errorInputClass);
          emailError.classList.add(activeErrorClass);
          emailError.innerText = requiredText;
          isValid = false;
        } else if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(emailInput.value) === false) {
          emailInput.classList.add(...errorInputClass);
          emailError.classList.add(activeErrorClass);
          emailError.innerText = 'Please enter a valid email address';
          isValid = false;
        } else if (emailInput.value.trim().length > 200) {
          emailInput.classList.add(...errorInputClass);
          emailError.classList.add(activeErrorClass);
          emailError.innerText = getMaxText(200);
          isValid = false;
        } else {
          emailError.classList.remove(activeErrorClass);
          emailInput.classList.remove(...errorInputClass);
        }

        if (!nameInput.value.trim()) {
          nameInput.classList.add(...errorInputClass);
          nameError.classList.add(activeErrorClass);
          nameError.innerText = requiredText;
          isValid = false;
        } else if (nameInput.value.trim().length > 500) {
          nameInput.classList.add(...errorInputClass);
          nameError.classList.add(activeErrorClass);
          nameError.innerText = getMaxText(500);
          isValid = false;
        } else {
          nameError.classList.remove(activeErrorClass);
          nameInput.classList.remove(...errorInputClass);
        }

        if (!companyInput.value.trim()) {
          companyInput.classList.add(...errorInputClass);
          companyError.classList.add(activeErrorClass);
          companyError.innerText = requiredText;
          isValid = false;
        } else if (companyInput.value.trim().length > 500) {
          companyInput.classList.add(...errorInputClass);
          companyError.classList.add(activeErrorClass);
          companyError.innerText = getMaxText(500);
          isValid = false;
        } else {
          companyError.classList.remove(activeErrorClass);
          companyInput.classList.remove(...errorInputClass);
        }

        if (!isValid || !checkboxInput.checked) {
          return;
        }

        fields.forEach(field => {
          const fieldName = field.id;

          data[fieldName] = field.value.replace(/\s+$/, '');
        });

        data['submitted_from'] = location.href;

        $.ajax({
          type: 'POST',
          url,
          data,
          success: (data) => {
            modal.classList.add('m-success');
            form.reset();
          },
          error: (data) => {
            const errorNode = document.createElement('p');
            errorNode.classList.add('m-error-text');
            errorNode.innerText = "There was a problem. Please try again later";
            description.parentNode.insertBefore(errorNode, description.nextSibling);
            console.log('failure ' + data)
          },
          headers: {
            'X-CSRFToken': getCookie('csrftoken')
          },
        });
      }
    });
  };
}
